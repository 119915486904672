$max-width:1440px;
$break-xs:1024px;


// body {

// }

.block-title {
    height: 64px;
    margin-bottom: 66px;

    img {
        height: 100%;
        width: auto;
    }

}

.block-title-center {
    height: 64px;
    margin-bottom: 66px;
    display: flex;
    justify-content: center;

    img {
        height: 100%;
        width: auto;
    }
}



@media screen and (max-width: $break-xs) {

    .block-title,
    .block-title-center {
        height: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
    }
}

.about {
    font-size: 24px;
    line-height: 40px;
    font-family: Hoefler;
    padding-top: 82px;
    padding-bottom: 143px;

    @media screen and (max-width: $break-xs) {
        flex-direction: column;
        padding-bottom: 32px;
        padding-top: 24px;
        font-size: 16px;
        line-height: 25px;
    }


    .title {
        font-size: 94px;
        font-family: Hoefler;
        line-height: 156px;
        padding-top: 82px;
        text-align: center;

        @media screen and (max-width: $break-xs) {
            font-size: 48px;
            line-height: 80px;
            padding-top: 24px;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('./title-1-s.svg')
            }
        }
    }

    .content {
        width: 67.5%;
        text-align: left;
        display: flex;
        align-items: center;

        @media screen and (max-width: $break-xs) {
            width: 100%;
        }

        span {
            font-weight: bold;
        }
    }
}

.projects {
    padding-bottom: 163px;

    @media screen and (max-width: $break-xs) {
        padding-bottom: 91px;
    }

    .slick-slider {
        .slick-dots {
            bottom: 0;

            li {
                button {
                    &:before {
                        color: #fff;
                        opacity: 1;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            color: #000000;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .title {
        text-align: center;
        font-size: 64px;
        line-height: 107px;
        font-family: Hoefler;
        margin-bottom: 90px;
        position: relative;
        width: fit-content;
        margin: 0 auto 90px;

        .line {
            position: absolute;
            background: #000000;
            opacity: 0.27;
            height: 16px;
            width: 266px;
            bottom: 32px;
            left: -20px;
        }

        @media screen and (max-width: $break-xs) {
            width: 100%;
            margin: 0 0 8px;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('./title-2-s.svg')
            }
        }

    }

    .mask-block {
        position: relative;
        cursor: pointer;

        @media screen and (max-width: $break-xs) {
            width: calc(100% - 15px);
            margin: auto;
        }

        &:hover {
            .mask {
                display: none;
            }
        }

        .mask {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000000 0% 0% no-repeat padding-box;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;


        }

        .mask-title {
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: -30px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
            color: #5A5A5A;
        }

        .mask1 {
            width: 24.1%;
        }

        .mask2 {
            width: 42.83%;
        }

        .mask3 {
            width: 34%;
        }

        .mask4 {
            width: 65.7%;
        }

        .mask5 {
            width: 29.76%;
        }
    }

    .col-2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &>div {
            width: calc((100% - 10px)/2);
        }

        @media screen and (max-width: $break-xs) {
            flex-direction: column;
            margin-bottom: 0;

            &>div {
                width: 100%;
                margin-bottom: 9px;
            }
        }
    }

    .col-3 {
        display: flex;
        justify-content: space-between;

        &>div {
            width: calc((100% - 20px)/3);
        }

        @media screen and (max-width: $break-xs) {
            flex-direction: column;

            &>div {
                width: 100%;
                margin-bottom: 9px;
            }
        }
    }
}

.services {

    margin: 0 auto 80px;

    .block-title-center {
        margin-bottom: 90px;
    }

    @media screen and (max-width: $break-xs) {
        margin: 0 auto 46px;

        .block-title-center {
            margin-bottom: 75.8px;
        }
    }


    .content {
        display: flex;
        justify-content: center;
        position: relative;

        @media screen and (max-width: $break-xs) {
            top: 0;
        }


        .right {
            width: 54%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;


            @media screen and (max-width: $break-xs) {
                width: 90%;
                margin: auto;
            }

            &>div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 100px;
                font-size: 24px;


                @media screen and (max-width: $break-xs) {
                    font-size: 16px;
                    margin-bottom: 32px;
                }


            }

            img {
                height: 80px;
                width: 100%;
                margin-bottom: 12px;

                @media screen and (max-width: $break-xs) {
                    height: 44px;
                    margin-bottom: 16px;
                }
            }
        }

    }
}

.clients {
    margin-bottom: 123px;

    @media screen and (max-width: $break-xs) {
        margin-bottom: 50px;
    }

    .title {
        text-align: center;
        font-size: 45px;
        line-height: 107px;
        font-family: Hoefler;
        margin-bottom: 50px;
        position: relative;
        width: fit-content;

        .line {
            position: absolute;
            background: #000000;
            opacity: 0.27;
            height: 16px;
            width: 169px;
            bottom: 32px;
            left: 0;
        }

        @media screen and (max-width: $break-xs) {
            font-size: 30px;
            line-height: 34px;
            letter-spacing: 3px;
            margin-bottom: 14px;
        }
    }

    .album {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $break-xs) {
            overflow-x: scroll;
        }

        &>div {
            width: calc((100% - 40px -88px)/6);

            @media screen and (max-width: $break-xs) {
                min-width: 82px;

            }
        }
    }
}

.partnership {
    margin-bottom: 124px;

    @media screen and (max-width: $break-xs) {
        margin-bottom: 38px;
    }

    .title {
        text-align: center;
        font-size: 45px;
        line-height: 107px;
        font-family: Hoefler;
        margin-bottom: 50px;
        position: relative;
        width: fit-content;

        .line {
            position: absolute;
            background: #000000;
            opacity: 0.27;
            height: 16px;
            width: 246px;
            bottom: 32px;
            left: 0;
        }

        @media screen and (max-width: $break-xs) {
            font-size: 30px;
            line-height: 34px;
            letter-spacing: 3px;
            margin-bottom: 14px;
        }
    }

    .album {
        display: flex;
        justify-content: space-between;


        @media screen and (max-width: $break-xs) {
            overflow-x: scroll;
        }

        &>div {
            width: calc((100% - 66px -88px)/7);

            @media screen and (max-width: $break-xs) {
                min-width: 81px;
            }
        }
    }
}

.touch {
    padding-top: 91px;
    margin-bottom: 158px;

    @media screen and (max-width: $break-xs) {
        padding-top: 0;
        margin-bottom: 82px;
    }

    .title {
        text-align: center;
        font-size: 64px;
        line-height: 107px;
        font-family: Hoefler;
        margin-bottom: 50px;
        position: relative;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto 66px;

        .line {
            position: absolute;
            background: #000000;
            opacity: 0.27;
            height: 16px;
            width: 246px;
            bottom: 32px;
            left: -12px;
        }

        @media screen and (max-width: $break-xs) {
            min-width: 100%;
            margin: 0 auto 22px;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('./title-4-s.svg')
            }
        }
    }
}

@media screen and (max-width: $break-xs) {
    .project-3-img {
        content: url('./project3-s.png')
    }

    .project-4-img {
        content: url('./project4-s.png')
    }

    .project-5-img {
        content: url('./project5-s.png')
    }

}