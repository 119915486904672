$max-width:1440px;
$break-xs:1024px;


.contact {

    padding: 0 44px 0;
    font-family: Hoefler;

    .address {
        font-size: 24px;
        line-height: 40px;
        padding-bottom: 52px;
        text-align: center;


    }

    @media screen and (max-width: $break-xs) {
        padding: 0;

        .address {
            line-height: 30px;
            font-size: 16px;
            padding-bottom: 24px;
        }

    }

    .contact-form {

        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $break-xs) {
            flex-direction: column;
            padding: 0;
        }

        &>div {
            width: calc((100% - 10px) / 2);

            @media screen and (max-width: $break-xs) {
                width: 100%;
            }

        }

        .describe {
            margin-bottom: 70px;

            @media screen and (max-width: $break-xs) {
                margin-bottom: 18px;
                font-weight: normal;
            }
        }

        .address {
            margin-bottom: 54px;

            @media screen and (max-width: $break-xs) {
                margin-bottom: 12px;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        form {

            @media screen and (max-width: $break-xs) {
                padding: 0;
                margin-top: 12px
            }

            input {
                height: 54px;
                width: 100%;
                margin-bottom: 20px;
                padding: 0 20px;
                font-size: 24px;
                color: #000000;
                border: 0;
                outline-width: 0;
                background: #F2F2F2;
                border-radius: 5px;

                

                @media screen and (max-width: $break-xs) {
                    margin-bottom: 8px;
                    font-size: 16px;
                    height: 35px;
                }
            }

            ::placeholder {
                color: #000000;
            }

            textarea {
                height: 126px;
                width: 100%;
                margin-bottom: 20px;
                padding: 18px 20px;
                font-size: 24px;
                color: #000000;
                border: 0;
                outline-width: 0;
                background: #F2F2F2;

                @media screen and (max-width: $break-xs) {
                    margin-bottom: 15px;
                    font-size: 16px;
                    height: 105px;
                }
            }

            .submit {
                color: #FFFFFF;
                width: 100%;
                height: 54px;
                background: #000000;
                border-radius: 5px;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                float: right;
                cursor: pointer;

                @media screen and (max-width: $break-xs) {
                    width: 100%;
                    height: 40px;
                    font-size: 16px;
                    border-radius: 5px
                }
            }
        }

        .google-map {
            width: 100%;
            height: 100%;

            @media screen and (max-width: $break-xs) {
                height: 150px;
            }
        }

        .google-place-name {
            font-size: 16px;
            width: 150px;
            color: #00828d;
        }
    }

}