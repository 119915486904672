$max-width:1440px;
$break-xs:1024px;

.footer-container {
    height: 89px;
    background: #292929;
    display: flex;
    font-family: Helvetica;

    .footer-inner {
        display: flex;
        max-width: 1300px;
        width: 100%;
        margin: auto;
        justify-content: space-between;
    }

    .footer-left {
        display: flex;
    }

    .logo {
        width: 216px;
        margin-right: 49px;
    }

    .contact-detail {
        color: #FFFFFF;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: flex-end;

        >div {
            margin-right: 27px;
        }
    }

    .copyright {

        color: #8B8B8B;
        font-size: 12px;
        line-height: 14px;

        text-align: right;
        display: flex;
        align-items: flex-end;

    }
}

@media screen and (max-width: $break-xs) {
    .footer-container {
        height: 115px;

        .footer-inner {
            flex-direction: column;
            align-items: center;
        }

        .logo {
            width: 170px;
            margin-bottom: 20px;
        }

        .copyright {
            text-align: center;
        }

    }
}