$max-width:1440px;
$break-xs:1023px;

.mobile-header-show {

    display: none;

    @media screen and (max-width: $break-xs) {
        display: flex;
    }

}

.header-container {
    width: 100%;
    font-family: Hoefler;
    position: relative;
    background-image: url('../imgs/banner.png');
    height: 800px;
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (max-width: $break-xs) {
        height: 286px;
        background-image: url('../imgs/banner-s.png');
    }


    img {
        width: 100%;
    }

    .menu-row {
        padding: 0 47px 24px 47px;
        display: flex;
        justify-content: space-between;
        height: 104px;
        align-items: flex-end;
        position: fixed;
        top: 0;
        z-index: 1;
        width: 100%;
        background: transparent linear-gradient(180deg, #000000 0%, #54545400 100%) 0% 0% no-repeat padding-box;


        @media screen and (max-width: $break-xs) {
            padding: 16px 16px 0;
            height: 34px;
        }

        .logo {
            width: 216px;

            @media screen and (max-width: $break-xs) {
                width: 87px;
                height: auto;
            }
        }

        .menu {
            cursor: pointer;
            width: 34px;

            @media screen and (max-width: $break-xs) {
                width: 30px;
            }
        }
    }

    .header-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 0 81px 0;


        @media screen and (max-width: $break-xs) {
            padding: 16px;
            justify-content: flex-end;
        }

        .logo {
            width: 191px;
            margin-bottom: 12px;
        }



        .title-bg {
            font-size: 174px;
            color: #FFFFFF;
            line-height: 189px;
            padding-left: 62px;
            font-family: Helvetica;

            @media screen and (max-width: $break-xs) {
                font-size: 50px;
                line-height: 55px;
                padding-left: 16px;
            }
        }

        .title-mid {
            font-size: 120px;
            color: #FFFFFF;
            padding-left: 62px;
            font-family: Helvetica;

            @media screen and (max-width: $break-xs) {
                font-size: 35px;
                padding-left: 16px;
            }
        }

        .title {
            font-size: 83px;
            line-height: 104px;
            text-align: right;
            letter-spacing: 0;
            color: #E6E6E6;
            padding-right: 69px;

            @media screen and (max-width: $break-xs) {
                font-size: 20px;
                line-height: 26px;
                padding-right: 0;
            }

            .name {
                font-size: 41px;
                line-height: 45px;

                @media screen and (max-width: $break-xs) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            .line {
                width: 267px;
                height: 18px;
                background: #FFFFFF;
                position: relative;
                left: 16px;
                bottom: 18px;

                @media screen and (max-width: $break-xs) {
                    width: 78px;
                    height: 5px;
                    bottom: 5px;
                    left: 0px;
                }
            }
        }
    }

    .triangle-container {
        display: none;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -44px;


        @media screen and (max-width: $break-xs) {
            bottom: -30px;
        }

        .triangle {
            width: 56px;
            height: 88px;
            margin: auto;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 27px;

            img {
                width: 56px;
                height: 88px;

                @media screen and (max-width: $break-xs) {
                    width: 39px;
                    height: 60px;
                }
            }

            @media screen and (max-width: $break-xs) {
                width: 39px;
                height: 60px;
            }
        }
    }
}