$break-md:1100px;
$max-width:1440px;
$break-xs:1024px;


* {
  box-sizing: border-box;
  font-family: Hoefler;
  outline-width: 0;
}

body {
  margin: 0;
}

img {
  width: 100%;
}


.container {
  width: 100%;
  max-width: $max-width;
  margin: auto;
  padding-left: 80px;
  padding-right: 80px;

  @media screen and (max-width: $break-xs) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.mobile-show {

  display: none;

  @media screen and (max-width: $break-xs) {
    display: block;
  }

}

.white-bg{
  background: #fff;
}

.mobile-show2 {

  display: none !important;

  @media screen and (max-width: $break-xs) {
    display: block !important;
  }

}

.pc-show {
  display: block;

  @media screen and (max-width: $break-xs) {
    display: none !important;
  }
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 200;
}


.content1 {
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 0;
  color: #5A5A5A;

  @media screen and (max-width: $break-xs) {
    font-size: 16px;
    line-height: 24px;
  }

}

.content2 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;
  color: #8B8B8B;

  @media screen and (max-width: $break-xs) {
    font-size: 14px;
    line-height: 15px;
  }
}